import React, {useState} from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	REDIRECT_URL_KEY
} from 'configs/AppConfig'
import {routes} from "../configs/routes";
import AuthService from "../services/AuthService";
import DataService from "../services/DataService";
import {AUTH_USER} from "../constants/AuthConstant";

const ProtectedRoute = () => {

	const {token} = useSelector(state => state.auth)
	const location = useLocation()
	const [updated, setUpdated] = useState(false);

	if (!token) {
		return <Navigate to={`${routes.login}?${REDIRECT_URL_KEY}=${location.pathname}`} replace/>;
	}

	const updateUser = async () => {
		await AuthService.me().then(response => {
			response = DataService.decryptObject(response)
			localStorage.setItem(AUTH_USER, JSON.stringify(response))
		}).catch(e => {})
	}

	if (!updated) {
		setUpdated(true)
		updateUser()
	}



	return <Outlet/>
}

export default ProtectedRoute