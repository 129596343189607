import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG } from 'configs/AppConfig'
import {LANG, SIDENAV_COL, THEME} from "../../constants/AppConstant";

export const initialState = THEME_CONFIG

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
        toggleCollapsedNav: (state, action) => {
			localStorage.setItem(SIDENAV_COL, String(action.payload))
			state.navCollapsed = action.payload
		},
        onLocaleChange: (state, action) => {
			localStorage.setItem(LANG, action.payload)
			state.locale = action.payload
		},
        onNavTypeChange: (state, action) => {
			state.navType = action.payload
		},
        onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload
		},
        onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
		},
        onSwitchTheme: (state, action) => {
			localStorage.setItem(THEME, action.payload)
			state.currentTheme = action.payload
		},
        onDirectionChange: (state, action) => {
			state.direction = action.payload
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
		},
	},
})

export const { 
    toggleCollapsedNav,
    onLocaleChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange,
	onBlankLayout
} = themeSlice.actions

export default themeSlice.reducer