import React from 'react'
import {routes} from "./routes";

export const publicRoutes = [
    {
        key: 'login',
        path: routes.login,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: routes.forget,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: routes.reset,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    },
    {
        key: 'assign-password',
        path: routes.assign,
        component: React.lazy(() => import('views/auth-views/authentication/assign-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: routes.dashboard,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'users',
        path: routes.users,
        component: React.lazy(() => import('views/app-views/user-list')),
    },
    {
        key: 'settings',
        path: routes.settings,
        component: React.lazy(() => import('views/app-views/setting')),
    },
    {
        key: 'support',
        path: routes.support,
        component: React.lazy(() => import('views/app-views/support')),
    },
    {
        key: 'cpanelUsers',
        path: routes.cpanelUsers,
        component: React.lazy(() => import('views/app-views/cpanel/user')),
    },
    {
        key: 'cpanelForwards',
        path: routes.cpanelForwards,
        component: React.lazy(() => import('views/app-views/cpanel/forward')),
    },
    {
        key: 'CpanelAutoresponders',
        path: routes.CpanelAutoresponders,
        component: React.lazy(() => import('views/app-views/cpanel/autoresponder')),
    },
    {
        key: 'newsletterUsers',
        path: routes.newsletterUsers,
        component: React.lazy(() => import('views/app-views/newsletter/users')),
    },
    {
        key: 'newsletterGroups',
        path: routes.newsletterGroups,
        component: React.lazy(() => import('views/app-views/newsletter/groups')),
    },
    {
        key: 'newsletterEmails',
        path: routes.newsletter,
        component: React.lazy(() => import('views/app-views/newsletter/campaigns')),
    },
    {
        key: 'newsletterTemplates',
        path: routes.newsletterTemplates,
        component: React.lazy(() => import('views/app-views/notifier/template')),
    },
    {
        key: 'notifierTemplateSetting',
        path: routes.notifierTemplateSetting,
        component: React.lazy(() => import('views/app-views/notifier/setting')),
    },
    {
        key: 'newsletterCompany',
        path: routes.newsletterCompany,
        component: React.lazy(() => import('views/app-views/notifier/company')),
    },
    {
        key: 'monitor',
        path: routes.monitor,
        component: React.lazy(() => import('views/app-views/monitor/error')),
    },
    {
        key: 'monitorEntity',
        path: routes.monitorEntity,
        component: React.lazy(() => import('views/app-views/monitor/entity')),
    },
]