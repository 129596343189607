const all = {
	SECRET_KEY : process.env.REACT_APP_SECRET_KEY,
	IV_KEY: process.env.REACT_APP_IV_KEY,
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL
}

const getEnv = () => {
	return all
}

export const env = getEnv()
