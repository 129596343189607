import axios, { CancelToken } from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';
import DataService from "../services/DataService";

const unauthorizedCode = [403, 401]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
})

const ongoingRequests = new Map();

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	config.headers['CUS-USER-PLATFORM'] = 'admin'
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken
	}

	// Generate a cancel token for the current request
	const source = CancelToken.source();

	// Check if there is a previous request for the same URL and method
	if (ongoingRequests.has(config.url + config.method)) {
		// Cancel the previous request
		ongoingRequests.get(config.url + config.method).cancel('Request canceled');
	}

	// Set the cancel token for the current request
	config.cancelToken = source.token;

	// Store the request's cancel token
	ongoingRequests.set(config.url + config.method, source);

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	if (response.config) {
		ongoingRequests.delete(response.config.url + response.config.method);
	}
	return response.data
}, (error) => {
	if (error.message === 'Request canceled') {
		return Promise.reject(error.message);
	}

	if (error.config) {
		ongoingRequests.delete(error.config.url + error.config.method);
	}

	let notificationParam = {
		message: error.response?.data?.title,
		description: error.response?.data?.detail
	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response.status)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)

		store.dispatch(signOutSuccess())
	}

	if (error.response.status === 404) {
		notificationParam.message = '404 - Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = '500 - Internal Server Error'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = '508 - Time Out'
	}

	if (error.response.status === 422) {
		notificationParam.message = '422 - Unprocessable Entity'
		const errorMessage = DataService.decryptObject(error.response?.data)
		notificationParam.description = errorMessage?.errors || 'no entity found'
	}


	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service